// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-jsx": () => import("./../../../src/templates/About/index.jsx" /* webpackChunkName: "component---src-templates-about-index-jsx" */),
  "component---src-templates-android-index-jsx": () => import("./../../../src/templates/Android/index.jsx" /* webpackChunkName: "component---src-templates-android-index-jsx" */),
  "component---src-templates-app-confirmation-index-jsx": () => import("./../../../src/templates/AppConfirmation/index.jsx" /* webpackChunkName: "component---src-templates-app-confirmation-index-jsx" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/Blog/index.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-blog-single-index-jsx": () => import("./../../../src/templates/BlogSingle/index.jsx" /* webpackChunkName: "component---src-templates-blog-single-index-jsx" */),
  "component---src-templates-category-index-jsx": () => import("./../../../src/templates/Category/index.jsx" /* webpackChunkName: "component---src-templates-category-index-jsx" */),
  "component---src-templates-contact-index-jsx": () => import("./../../../src/templates/Contact/index.jsx" /* webpackChunkName: "component---src-templates-contact-index-jsx" */),
  "component---src-templates-homepage-index-jsx": () => import("./../../../src/templates/Homepage/index.jsx" /* webpackChunkName: "component---src-templates-homepage-index-jsx" */),
  "component---src-templates-how-to-play-index-jsx": () => import("./../../../src/templates/HowToPlay/index.jsx" /* webpackChunkName: "component---src-templates-how-to-play-index-jsx" */),
  "component---src-templates-how-to-play-single-index-jsx": () => import("./../../../src/templates/HowToPlaySingle/index.jsx" /* webpackChunkName: "component---src-templates-how-to-play-single-index-jsx" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/Jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-licensing-js": () => import("./../../../src/templates/Licensing.js" /* webpackChunkName: "component---src-templates-licensing-js" */),
  "component---src-templates-terms-index-jsx": () => import("./../../../src/templates/Terms/index.jsx" /* webpackChunkName: "component---src-templates-terms-index-jsx" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/Thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */),
  "component---src-templates-winners-index-jsx": () => import("./../../../src/templates/Winners/index.jsx" /* webpackChunkName: "component---src-templates-winners-index-jsx" */)
}

